// This is a bit complex, and personally I don't love the javascript IntersectionObserver API, but
// this is just taken from https://codepen.io/NoBBB/pen/MWWXWVZ. We might be able to replace this
// with a library implementation, but it works and is compact so ¯\_(ツ)_/¯

export default function onScrollIntoView(target, callback) {
  const thresholdArray = (steps) =>
    Array(steps + 1)
      .fill(0)
      .map((_, index) => index / steps || 0);

  let previousY = 0;
  let previousRatio = 0;

  const handleIntersect = (entries) => {
    if (entries[0].intersectionRatio <= 0) return;

    entries.forEach((entry) => {
      const currentY = entry.boundingClientRect.y;
      const currentRatio = entry.intersectionRatio;
      const isIntersecting = entry.isIntersecting;

      // Scrolling down/up
      if (currentY < previousY) {
        if (currentRatio > previousRatio && isIntersecting) {
          // console.log('Scrolling down enter')
          callback();
        } else {
          // console.log('Scrolling down leave')
        }
      } else if (currentY > previousY && isIntersecting) {
        if (currentRatio < previousRatio) {
          // console.log('Scrolling up leave')
        } else {
          // console.log('Scrolling up enter')
          callback();
        }
      }

      previousY = currentY;
      previousRatio = currentRatio;
    });
  };

  const observer = new IntersectionObserver(handleIntersect, {
    threshold: thresholdArray(20),
  });

  observer.observe(target);
}

// This is a bit of a workaround, but basically, we set the active class on links whenever the
// user clicks a link, or scrolls the target content into view. The problem is that these two
// events can fire somewhat simultaneously - when a user clicks the link, this scrolls content
// into view, in turn triggering multiple "scrollIntoView" events, which then set the active class
// on their links. To work around this, we disable scroll-into-view events after clicking the link,
// and re-enable shortly after. This would be a lot nicer in React, but it's probably not worth a
// re-write without other, bigger reasons :)
export function disableScrollTriggers(callback) {
  return function () {
    window.disableScrollTriggers = true;

    callback();

    setTimeout(() => {
      window.disableScrollTriggers = undefined;
    }, 300);
  };
}

export function ifScrollTriggersEnabled(callback) {
  return function () {
    if (window.disableScrollTriggers) return;
    callback();
  };
}
