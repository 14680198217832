import onDocReady from "../utils/onDocReady";

// Copy an input element's contents on click
onDocReady(() => {
  document.querySelectorAll('[data-copy-on-click-target]').forEach((target) => {
    target.addEventListener("click", () => {
      var targetSelector = $(this).data().copyOnClickTarget;
      var target = $(targetSelector);
      target.select();
      document.execCommand("copy");

      var Toast = window['app-bridge'].actions.Toast;

      Toast.create(app, {
        message: "Copied to clipboard",
        duration: 5000,
      }).dispatch(Toast.Action.SHOW);
    });
  });
});
