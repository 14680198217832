import onDocReady from "../utils/onDocReady";

// A custom jquery plugin for charts that fetch data using graphql queries
onDocReady(() => {
  document.querySelectorAll(".graphql-chart").forEach(function(chart) {
    var ctx = this.getContext("2d");
    var graphqlQuery = chart.data().graphqlQuery;
    var options = chart.data().graphqlChartOptions || {};
    var data = JSON.stringify({ query: graphqlQuery });
    var contentType = "application/json";

    $.ajax({ url: "/graphql", contentType, type: "POST", data }).then(function ({ data }) {
      // GraphQL returns results nested in an object. Take the data from the first (and only!) key
      var dataKey = Object.keys(data)[0];
      var chartData = data[dataKey];

      initializeChart(chartData);

      var hasData = chartData.datasets.some(({ data }) => data.some(value => value !== 0))
      if(!hasData) chart.parent().addClass('graphql-chart--empty');
    });

    function initializeChart(data) {
      var config = {
        type: "bar",
        options,
        data: {
          labels: data.labels,
          datasets: data.datasets.map(configureDataset),
        },
      };

      new Chart(ctx, config);
    }

    // Add some default values to the dataset to make it a little prettier
    function configureDataset(dataset) {
      return {
        borderColor: dataset.color,
        pointBorderColor: "#FFF",
        pointBackgroundColor: dataset.color,
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 1,
        pointRadius: 4,
        fill: true,
        borderWidth: 2,
        ...dataset,
      };
    }
  });
})
