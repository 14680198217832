import onScrollIntoView, { disableScrollTriggers, ifScrollTriggersEnabled } from "./utils/onScrollIntoView";

// Decorate our sidebar links with some magic for adding and removing the active class. The
// disableScrollTriggers / ifScrollTriggersEnabled functions exist to prevent some conflicts between
// events - see related comments.
document.addEventListener("DOMContentLoaded", function () {
  const parent = document.querySelector(".manage-docs-links-active-class");

  if (!parent) return;

  const allLinks = parent.querySelectorAll("a");
  const allListItems = parent.querySelectorAll("li");

  allLinks.forEach((link) => {
    link.addEventListener(
      "click",
      disableScrollTriggers(() => setLinkFocus(link))
    );

    const contentElement = document.querySelector(link.hash);
    onScrollIntoView(
      contentElement,
      ifScrollTriggersEnabled(() => setLinkFocus(link))
    );
  });

  // Set the active class on a list item, after first removing the active class from all other items
  function setLinkFocus(link) {
    const listItem = link.closest("li");
    if (!listItem) return;

    allListItems.forEach((li) => li.classList.remove("uk-active"));

    listItem.classList.add("uk-active");
  }
});
